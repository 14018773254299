/**
 * @generated SignedSource<<ad4c742da6495e66c9d9391dda887af8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "CHF" | "DKK" | "EUR" | "GBP" | "JPY" | "MXN" | "NOK" | "SEK" | "USD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useShippingPrice_item$data = {
  readonly relevantShippingInfo?: {
    readonly shipmentQuote: {
      readonly buyerTotalAmount: {
        readonly convertedAmountList: ReadonlyArray<{
          readonly amount: number | null;
          readonly currency: Currency | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"shippingCostHelpers_item">;
  readonly " $fragmentType": "useShippingPrice_item";
};
export type useShippingPrice_item$key = {
  readonly " $data"?: useShippingPrice_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"useShippingPrice_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "buyerId"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "fetchLiveShipmentQuote"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "fetchShippingCostInfo"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "itemQuantity"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "userCountryCode"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "userZipCode"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useShippingPrice_item",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "shippingCostHelpers_item",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vertical",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ShipmentQuote",
          "kind": "LinkedField",
          "name": "shipmentQuotes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isComplimentary",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isCalculated",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "FinancialAmount",
              "kind": "LinkedField",
              "name": "totalAmount",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ConvertedAmounts",
                  "kind": "LinkedField",
                  "name": "convertedAmounts",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "USD",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ShipmentServiceMethod",
              "kind": "LinkedField",
              "name": "serviceMethod",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Region",
                  "kind": "LinkedField",
                  "name": "region",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "regionName",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "condition": "fetchShippingCostInfo",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "buyerId",
              "variableName": "buyerId"
            },
            {
              "kind": "Variable",
              "name": "countryCode",
              "variableName": "userCountryCode"
            },
            {
              "kind": "Variable",
              "name": "quantity",
              "variableName": "itemQuantity"
            },
            {
              "kind": "Variable",
              "name": "useLiveEndpoint",
              "variableName": "fetchLiveShipmentQuote"
            },
            {
              "kind": "Variable",
              "name": "zipCode",
              "variableName": "userZipCode"
            }
          ],
          "concreteType": "RelevantShippingInfo",
          "kind": "LinkedField",
          "name": "relevantShippingInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ShipmentQuote",
              "kind": "LinkedField",
              "name": "shipmentQuote",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "FinancialAmount",
                  "kind": "LinkedField",
                  "name": "buyerTotalAmount",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ConvertedAmountList",
                      "kind": "LinkedField",
                      "name": "convertedAmountList",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "currency",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "amount",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "507dd9447500f48e31360da1cd54e94c";

export default node;
